<vt-styled-authorization-page
  [content]="passwordForm"
  class="forgot-password__whitelabel-bg"
></vt-styled-authorization-page>

<ng-template  #passwordForm>
    <vt-forgot-password-request-form
      (submitted)="onSubmit($event)">
    </vt-forgot-password-request-form>
</ng-template>
