import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const BASE64_PNG_TAG = 'data:image/png;base64,';
const BASE64_SVG_TAG = 'data:image/svg+xml;base64,';
const BASE64_XML_TAG = 'data:text/xml;base64,';

@Component({
  selector: 'vt-base64-image-display',
  templateUrl: './base64-image-display.component.html',
  styleUrls: ['./base64-image-display.component.scss'],
})
export class Base64ImageDisplayComponent {
  @Input() imageSource: string;

  constructor(private readonly _sanitizer: DomSanitizer) {}

  get image(): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(addBase64Tag(stripBase64Tag(this.imageSource)));
  }
}

/**
 * Strips the base64 tag from the provided value.
 * @param {string} value The value.
 */
export function stripBase64Tag(value: string) {
  if (value && value.startsWith(BASE64_SVG_TAG)) {
    return value.replace(BASE64_SVG_TAG, '');
  }

  return value ? value.replace(BASE64_PNG_TAG, '') : '';
}

/**
 * Checks if the base 64 tag contains png or svg.
 * @param {string} value The value.
 */
export function checkBase64Tag(value: string) {
  return value.startsWith(BASE64_PNG_TAG) || value.startsWith(BASE64_SVG_TAG) || value.startsWith(BASE64_XML_TAG);
}

/**
 * Adds the base64 tag to the provided value.
 * PHN2 is SVG tag, PD94 is XML tag
 * @param {string} value The value.
 */
export function addBase64Tag(value: string) {
  if (value.startsWith('PHN2') || value.startsWith('PD94')) {
    return BASE64_SVG_TAG + value;
  }

  return BASE64_PNG_TAG + value;
}
