import { Injectable } from '@angular/core';
import { Settings } from '@app/core/models/settings';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';

@Injectable()
export class AppConfigService {
  /**
   * The loaded config
   *
   * @static
   * @type {Settings}
   * @memberof AppConfigService
   */
  static config: Settings;

  /**
   *Creates an instance of AppConfigService.
   * @param {HttpClient} http
   * @memberof AppConfigService
   */
  constructor(private http: HttpClient) {}

  /**
   * Load the settings from assets/app-settings.json file
   *
   * @memberof AppConfigService
   */
  async loadSettings() {
    let jsonFile = environment.production ? `assets/app-settings.json` : `assets/app-settings.local.json`;
   
    await new Promise<void>((resolve, reject) => {
      this.http
        .get(jsonFile)
        .toPromise()
        .then((response: any) => {
          AppConfigService.config = <Settings>response;      
          resolve(); //Return Sucess
        })
        .catch((response: any) => {
          console.log('Failed to load appSettings:', response);
          resolve();
        });
    });
  }

  public getEnvironment(): string{
    return AppConfigService.config.App.Environment;
  }
}
