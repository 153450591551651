import { Component } from "@angular/core";
import * as fromRoot from '@app/reducers';
import * as fromStrings from '@app/reducers/strings';
import { Store } from "@ngrx/store";
import { Observable } from 'rxjs';

@Component({
    selector: 'vt-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss'],
})

export class InformationComponent {

  message$: Observable<string>;

  constructor(
    private readonly store: Store<fromRoot.State>,
    ) {
    this.message$ = this.store.select(fromStrings.getRegistrationSuccessText)
  }
}
