import { ClaimType } from '@app/core/models/Claims/ClaimType';
import { ClaimAction } from '@app/core/models/Claims/ClaimAction';

const CLAIMS_NAME = 'CLAIMS';

/**
 * Get the full name for the claims.
 * @param {ClaimType} claimType The type of claim.
 * @param {ClaimAction[]} action The claim actions.
 * @returns The full names.
 */
export function getClaims(claimType: ClaimType, action: ClaimAction[]): string[] {
  return action.map(act => `${claimType}.${act}`);
}

/**
 * Check if user has the required claims.
 * @param {string[]} requiredClaims The claims that are required.
 * @returns {boolean} User has required claims.
 */
export function hasClaims(requiredClaims: string[]): boolean {
  if (!requiredClaims) {
    return false;
  }

  const claims = getClaimsFromLocalStorage();
  if (!claims || !requiredClaims.every(rc => claims.some(c => c === rc))) {
    return false;
  }

  return true;
}

/**
 * Get claims from local storage.
 * @returns {string[]} The claims.
 */
export function getClaimsFromLocalStorage(): string[] {
  const claims = localStorage.getItem(CLAIMS_NAME);
  return !!claims ? claims.split(',') : [];
}

/**
 * Set claims in local storage.
 * @param {string[]} claims The claims.
 */
export function setClaimsInLocalStorage(claims: string[]): void {
  localStorage.setItem(CLAIMS_NAME, claims.join(','));
}

/**
 * Remove claims from local storage.
 */
export function removeClaimsInLocalStorage(): void {
  localStorage.removeItem(CLAIMS_NAME);
}
