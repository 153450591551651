import { createSelector, createFeatureSelector, ActionReducerMap } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/reducers/auth.reducer';
import * as fromLayout from '@app/core/reducers/layout.reducer';
import * as fromLoginPage from '@app/auth/reducers/login-page.reducer';
import * as fromRegisterUserPage from '@app/auth/reducers/register-page.reducer';
import { AuthApiActions } from '@app/auth/actions';
import { PortalSettingType } from '@app/core/enums/portal-setting-type';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  registerUserPage: fromRegisterUserPage.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState, AuthApiActions.AuthApiActionsUnion> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  registerUserPage: fromRegisterUserPage.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);
export const getUser = createSelector(
  selectAuthStatusState,
  fromAuth.getUser
);
export const getLoggedIn = createSelector(
  getUser,
  user => !!user
);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError
);

export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);

export const selectRegisterUserPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.registerUserPage
);

export const getRegisterUserPageErrors = createSelector(
  selectRegisterUserPageState,
  fromRegisterUserPage.getErrors
);

export const selectLayoutState = createFeatureSelector<fromLayout.LayoutState>('layout');

export const getTokens = createSelector(
  selectAuthStatusState,
  fromAuth.getTokens
);
export const getErrorSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getErrorSnackBar
);
export const getShowErrorSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getShowErrorSnackBar
);

export const getSuccessSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getSuccessSnackBar
);
export const getShowSuccessSnackBar = createSelector(
  selectLayoutState,
  fromLayout.getShowSuccessSnackBar
);

export const getPortal = createSelector(selectLayoutState, fromLayout.getPortal);

export const getLogo = createSelector(selectLayoutState, fromLayout.getLogo);
export const getTitle = createSelector(selectLayoutState, fromLayout.getTitle);
