import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RegisterUser } from '@app/auth/models/registerUser';
import { UntypedFormGroup, Validators, FormBuilder, AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { equalControlsValidator } from '@app/core/validators/equalControls.validator';
import { Error } from '@app/core/models/Error';

@Component({
  selector: 'vt-register-user-form',
  templateUrl: './register-user-form.component.html',
  styleUrls: ['./register-user-form.component.scss'],
})
export class RegisterUserFormComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() registerUser: RegisterUser;

  @Input() errors: Error[];

  @Output() submitted = new EventEmitter<RegisterUser>();

  form: UntypedFormGroup;

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      Customer: new UntypedFormGroup({
        Name: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        Street: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        StreetNumber: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        StreetNumberAddition: new UntypedFormControl(''),
        PostalCode: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        City: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        IsApproved: new UntypedFormControl(false, { validators: [Validators.required], updateOn: 'blur' }),
      }),
      User: new UntypedFormGroup({
        FirstName: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        LastNamePrefix: new UntypedFormControl(''),
        LastName: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        PhoneNumber: new UntypedFormControl(''),
        Email: new UntypedFormControl('', { validators: [Validators.email, Validators.required], updateOn: 'blur' }),
        EmailRepeat: new UntypedFormControl('', { validators: [Validators.email, Validators.required], updateOn: 'blur' }),
        Password: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        PasswordRepeat: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      }, [this.validatePasswords, this.validateEmails]),
    });
  }

  /**
   * Validator to check if password and password repeat are provided and equal.
   * @param {AbstractControl} form The form.
   * @returns {ValidationErrors | null} Error object.
   * @memberof RegisterUserFormComponent
   */
  validatePasswords(form: AbstractControl): ValidationErrors | null {  
    return equalControlsValidator(form.get('Password'), form.get('PasswordRepeat'));
  }

  /**
   * Validator to check if email and email repeat are provided, equal and valid emails.
   * @param {AbstractControl} form The form.
   * @returns {ValidationErrors | null} Error object.
   * @memberof RegisterUserFormComponent
   */
  validateEmails(form: AbstractControl): ValidationErrors | null { 
    return equalControlsValidator(form.get('Email'), form.get('EmailRepeat'));
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }
}
