import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageDialogParameters, ButtonType } from '@app/core/models/message-dialog-parameters';

@Component({
  templateUrl: 'message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
  header: string;
  message: string;
  buttonType: ButtonType = ButtonType.Ok;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogParameters,
    private readonly dialogRef: MatDialogRef<MessageDialogComponent>
  ) {
    this.header = data.header;
    this.message = data.message;
    this.buttonType = data.buttonType;
  }

  close() {
    this.dialogRef.close(this.data);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  isOk(): boolean {
    return this.buttonType === ButtonType.Ok;
  }

  isYesNo(): boolean {
    return this.buttonType === ButtonType.YesNo;
  }
}
