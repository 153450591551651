import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/guards/auth-guard.service';
import { ClaimGuard } from '@app/auth/guards/claim-guard.service';
import { NotFoundPageComponent } from '@app/core/containers/not-found-page.component';

const plinthBlockClaims = ['PlinthBlock.Read'];
const connectorClaims = ['Connector.Read'];
const profileTypeClaims = ['ProfileType.Read'];
const profileClaims = ['Profile.Read'];
const sectionTypeClaims = ['SectionType.Read'];
const customerClaims = ['Customer.Read'];
const productClaims = ['Product.Read'];
const residualLengthClaims = ['ResidualLength.Read'];
const userProfileClaims = ['UserProfile.Read'];
const orderClaims = ['Order.Read'];
const rabbetClaims = ['Rabbet.Read'];
const rabbetDetailClaims = ['RabbetDetail.Read'];
const drillPatternClaims = ['DrillPattern.Read'];
const productTypesClaims = ['ProductType.Read'];
const articlesClaims = ['Article.Read'];
const stockPlinthBlockClaims = ['StockPlinthBlock.Read'];
const xmlDetailClaims = ['XmlDetail.Read'];

export const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'userprofile',
        loadChildren: () => import('@app/user-profiles/user-profiles.module').then(m => m.UserProfilesModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: userProfileClaims
        }
    },
    { path: 'auth', loadChildren: () => import('@app/auth/auth.module').then(m => m.AuthModule) },
    {
        path: 'users',
        loadChildren: () => import('@app/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'plinthblocks',
        loadChildren: () => import('@app/plinth-blocks/plinth-blocks.module').then(m => m.PlinthBlocksModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: plinthBlockClaims
        }
    },
    {
        path: 'connectors',
        loadChildren: () => import('@app/connectors/connectors.module').then(m => m.ConnectorsModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: connectorClaims
        }
    },
    {
        path: 'profiletypes',
        loadChildren: () => import('@app/profile-types/profile-types.module').then(m => m.ProfileTypesModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: profileTypeClaims
        }
    },
    {
        path: 'sectiontypes',
        loadChildren: () => import('@app/section-types/section-types.module').then(m => m.SectionTypesModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: sectionTypeClaims
        }
    },
    {
        path: 'profiles',
        loadChildren: () => import('@app/profiles/profiles.module').then(m => m.ProfilesModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: profileClaims
        }
    },
    {
        path: 'customers',
        loadChildren: () => import('@app/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: customerClaims
        }
    },
    {
        path: 'products',
        loadChildren: () => import('@app/products/products.module').then(m => m.ProductsModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: productClaims
        }
    },
    {
        path: 'residuallengths',
        loadChildren: () => import('@app/residual-lengths/residual-lengths.module').then(m => m.ResidualLengthsModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: residualLengthClaims
        }
    },
    {
        path: 'wizard',
        loadChildren: () => import('@app/product-wizard/product-wizard.module').then(m => m.ProductWizardModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'cylinder-wizard',
        loadChildren: () => import('@app/cylinder-wizard/cylinder-wizard.module').then(m => m.CylinderWizardModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'orders',
        loadChildren: () => import('@app/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: orderClaims
        }
    },
    {
        path: 'rabbets',
        loadChildren: () => import('@app/rabbet/rabbets.module').then(m => m.RabbetsModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: rabbetClaims
        }
    },
    {
        path: 'rabbetdetails',
        loadChildren: () => import('@app/rabbet-details/rabbet-details.module').then(m => m.RabbetDetailModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: rabbetDetailClaims
        }
    },
    {
        path: 'drillpatterns',
        loadChildren: () => import('@app/drill-patterns/drill-patterns.module').then(m => m.DrillPatternsModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: drillPatternClaims
        }
    },
    {
        path: 'stockplinthblocks',
        loadChildren: () => import('@app/stock-plinth-blocks/stock-plinth-block.module').then(m => m.StockPlinthBlocksModule),
        canActivate: [AuthGuard],
        data: {
          requiredClaims: stockPlinthBlockClaims
        }
    },
    {
        path: 'producttypes',
        loadChildren: () => import('@app/product-types/product-type.module').then(m => m.ProductTypeModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: productTypesClaims
        }
    },
    {
        path: 'articles',
        loadChildren: () => import('@app/articles/articles.module').then(m => m.ArticlesModule),
        canActivate: [ClaimGuard],
        data: {
            requiredClaims: articlesClaims
        }
    },
    {
        path: 'xmlcouplings',
        loadChildren: () => import('@app/xml-coupling/xml-coupling.module').then(m => m.XmlCouplingsModule),
        canActivate: [AuthGuard],
        data: {
            requiredClaims: xmlDetailClaims
        }
    },
    { path: '**', component: NotFoundPageComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
