import { AuthApiActions, RegisterUserPageActions } from '@app/auth/actions';
import { Error } from '@app/core/models/Error';

export interface State {
  errors: Error[];
  pending: boolean;
}

export const initialState: State = {
  errors: [],
  pending: false,
};

export function reducer(
  state = initialState,
  action: | AuthApiActions.AuthApiActionsUnion| RegisterUserPageActions.RegisterUserPageActionsUnion
): State {
  switch (action.type) {
    case RegisterUserPageActions.RegisterUserPageActionTypes.SubmitRegisterUser: {
      return { ...state, errors: [], pending: true };
    }
    case AuthApiActions.AuthApiActionTypes.RegisterUserSuccess: {
      return { ...state, errors: [], pending: false };
    }
    case AuthApiActions.AuthApiActionTypes.RegisterUserFailure: {       
      return { ...state, errors: action.payload, pending: false };
    }
    default: {
      return state;
    }
  }
}

export const getErrors = (state: State) => state.errors;
export const getPending = (state: State) => state.pending;
