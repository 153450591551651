import { Action } from '@ngrx/store';
import { SnackBar } from '@app/core/models/SnackBar';
import { Portal } from '../models/Portal/portal.model';

export enum LayoutActionTypes {
  OpenErrorSnackBar = '[Layout] Open Error SnackBar',
  CloseErrorSnackBar = '[Layout] Close Error SnackBar',
  OpenSuccessSnackBar = '[Layout] Open Success SnackBar',
  CloseSuccessSnackBar = '[Layout] Close Success SnackBar',
  DownloadHelp = '[Layout] Download Help',
  StickerPrintFailed = '[Layout] Sticker Print Failed',
  StickerPrintFailedConfirmationDismiss = '[Layout] Sticker Print Failed Confirmation Dismiss',
  LoadStyle = '[Layout] Load Style',
  LoadStyleSuccess = '[Layout] Load Style Success',
  LoadStyleFailure = '[Layout] Load Style Failure',
  CheckValidPortal = '[Layout] Check Valid Portal',
  CheckValidPortalSuccess = '[Layout] Check Valid Portal Success',
  CheckValidPortalFailure = '[Layout] Check Valid Portal Failure',
}

export class OpenErrorSnackBar implements Action {
  readonly type = LayoutActionTypes.OpenErrorSnackBar;

  constructor(public payload: SnackBar) {}
}

export class CloseErrorSnackBar implements Action {
  readonly type = LayoutActionTypes.CloseErrorSnackBar;
}

export class OpenSuccessSnackBar implements Action {
  readonly type = LayoutActionTypes.OpenSuccessSnackBar;

  constructor(public payload: SnackBar) {}
}

export class CloseSuccessSnackBar implements Action {
  readonly type = LayoutActionTypes.CloseSuccessSnackBar;
}

export class DownloadHelp implements Action {
  readonly type = LayoutActionTypes.DownloadHelp;
}

export class StickerPrintFailed implements Action {
  readonly type = LayoutActionTypes.StickerPrintFailed;

  constructor(public payload: string[]) {}
}

export class StickerPrintFailedConfirmationDismiss implements Action {
  readonly type = LayoutActionTypes.StickerPrintFailedConfirmationDismiss;
}

export class LoadStyle implements Action {
  readonly type = LayoutActionTypes.LoadStyle;
}

export class LoadStyleSuccess implements Action {
  readonly type = LayoutActionTypes.LoadStyleSuccess;

  constructor(public payload: Portal) {}
}

export class LoadStyleFailure implements Action {
  readonly type = LayoutActionTypes.LoadStyleFailure;

  constructor(public payload: Error[]) {}
}

export class CheckValidPortal implements Action {
  readonly type = LayoutActionTypes.CheckValidPortal;
}

export class CheckValidPortalSuccess implements Action {
  readonly type = LayoutActionTypes.CheckValidPortalSuccess;

  constructor(public payload: boolean) {}
}

export class CheckValidPortalFailure implements Action {
  readonly type = LayoutActionTypes.CheckValidPortalFailure;

  constructor(public payload: Error[]) {}
}

export type LayoutActionsUnion =
OpenErrorSnackBar
| CloseErrorSnackBar
| OpenSuccessSnackBar
| CloseSuccessSnackBar
| DownloadHelp
| StickerPrintFailed
| StickerPrintFailedConfirmationDismiss
| LoadStyle
| LoadStyleSuccess
| LoadStyleFailure
| CheckValidPortal
| CheckValidPortalSuccess
| CheckValidPortalFailure;
