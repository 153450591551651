import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RefreshRequest } from '@app/auth/models/refreshrequest';
import {
  getClaimsFromLocalStorage,
  hasClaims,
  removeClaimsInLocalStorage,
  setClaimsInLocalStorage,
} from '@app/core/models/Claims/ClaimFunctions';
import { setFeaturesInLocalStorage, removeFeaturesInLocalStorage } from '@app/core/functions/feature-flag.functions';
import { AppConfigService } from '@app/core/services/app-config-service';

export const ACCESS_TOKEN_NAME = 'ACCESS_TOKEN';
export const REFRESH_TOKEN_NAME = 'REFRESH_TOKEN';
export const IS_CUSTOMER_NAME = 'IS_CUSTOMER';
export const VERSION_NUMBER = 'VERSION_NUMBER';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  /**
   * Helper class for JSON Web Tokens.
   */
  private readonly jwtHelperService = new JwtHelperService();

  /**
   * Gets the token object.
   */
  getTokenObject(): RefreshRequest {
    return {
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefreshToken(),
      claims: getClaimsFromLocalStorage(),
    };
  }

  /**
   * Gets the AccessToken.
   */
  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_NAME)?? '';
  }

  /**
   * Gets the refresh token.
   */
  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME) ?? '';
  }

  /**
   * Gets the is customer setting.
   */
  getIsCustomer(): boolean | undefined {
    const isCustomer = localStorage.getItem(IS_CUSTOMER_NAME);
    return JSON.parse(isCustomer) ?? null;
  }

  canExpediteDeliveryDate(): boolean {
    return hasClaims(['EarlyDeliveryDate.Create']);
  }

  /**
   * Sets the tokens.
   * @param {string} token The AccessToken.
   * @param {string} refreshToken The RefreshToken.
   * @param {string[]} claims The Claims.
   * @param {string[]} features The Features.
   */
  setToken(token: string, refreshToken: string, claims: string[], features: string[], isCustomer: boolean, versionNumber: string): void {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
    setClaimsInLocalStorage(claims);
    setFeaturesInLocalStorage(features);
    localStorage.setItem(IS_CUSTOMER_NAME, isCustomer.toString());
    localStorage.setItem(VERSION_NUMBER, versionNumber);
  }

  /**
   * Removes the tokens from the local storage.
   */
  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
    removeClaimsInLocalStorage();
    removeFeaturesInLocalStorage();
    localStorage.removeItem(IS_CUSTOMER_NAME);
    localStorage.removeItem(VERSION_NUMBER);
  }

  /**
   * Whether or not the tokens are present.
   */
  hasToken(): boolean {
    return !!localStorage.getItem(ACCESS_TOKEN_NAME) && !!localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  /**
   * Gets the expiration date of the AccessToken.
   */
  getTokenExpirationDate(): Date {
    return this.jwtHelperService.getTokenExpirationDate(this.getAccessToken()) ?? new Date();
  }

  /**
   * Whether or not the AccessToken is expired.
   */
  isTokenExpired(): boolean {
    const accessToken = this.getAccessToken();
    const date = this.jwtHelperService.getTokenExpirationDate(accessToken);
    const isExpired = this.jwtHelperService.isTokenExpired(accessToken);
    return !date ? false : isExpired || date.getTime() < new Date().getTime();
  }

  /**
   * Whether or not the AccessToken should be refreshed.
   */
  shouldRefreshToken(): boolean {
    const requestDifference = AppConfigService.config
      ? AppConfigService.config.Tokens.RequestDifference
      : 0;

    return (
      this.isTokenExpired() ||
      this.getTokenExpirationDate().getTime() - new Date().getTime() <= requestDifference
    );
  }
}
