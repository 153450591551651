<ng-container *ngIf="isValidPortalLoaded$ | async; then loaded; else loading"></ng-container>

<ng-template #loading>
  <p>
    Aan het laden...
  </p>
</ng-template>

<ng-template #loaded>
  <ng-container *ngIf="isValidPortal$ | async; then valid; else notFound"></ng-container>
</ng-template>

<ng-template #valid>
  <vt-layout>
    <router-outlet></router-outlet>
  </vt-layout>
</ng-template>


<ng-template #notFound>
  <p>
    404: Portal niet gevonden
  </p>
</ng-template>
