import { AuthApiActions, AuthActions } from '@app/auth/actions';
import { Token } from '@app/auth/models/token';
import { User } from '@app/users/models/user';

export interface State {
  user: User | null;
  tokens: Token | null;
}

export const initialState: State = {
  user: null,
  tokens: null
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | AuthActions.AuthActionsUnion
): State {
  switch (action.type) {
    case AuthApiActions.AuthApiActionTypes.LoginSuccess: {
      return { ...state, tokens: action.payload.tokens };
    }
    case AuthActions.AuthActionTypes.Logout: {
      localStorage.clear();
      return initialState;
    }
    case AuthApiActions.AuthApiActionTypes.RetrieveUserSuccess: {
      return { ...state, user: action.payload.user };
    }
    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;

export const getTokens = (state: State) => state.tokens;
