import { ActionReducerMap, createSelector, createFeatureSelector, ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import * as fromRouter from '@ngrx/router-store';

import * as fromLayout from '@app/core/reducers/layout.reducer';
import { PortalSettingType } from '@app/core/enums/portal-setting-type';

export interface State {
  layout: fromLayout.LayoutState;
  router: fromRouter.RouterReducerState;
}

export const reducers: ActionReducerMap<State, any> = {
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
}

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

export const getLayoutState = createFeatureSelector<fromLayout.LayoutState>('layout');

export const getPortalSettingValue = (portalSettingType: PortalSettingType) => 
createSelector(getPortalSettings, (portalSettings) => portalSettings.find(x => x.settingType === portalSettingType).value);

export const getErrorSnackBar = createSelector(getLayoutState, fromLayout.getErrorSnackBar);
export const getShowErrorSnackBar = createSelector(getLayoutState, fromLayout.getShowErrorSnackBar);

export const getIsValidPortal = createSelector(getLayoutState, fromLayout.getIsValidPortal);
export const getIsValidPortalLoaded = createSelector(getLayoutState, fromLayout.getIsValidPortalLoaded);

export const getPortalSettings = createSelector(getLayoutState, fromLayout.getPortalSettings);

export const getPortalIdentifier = createSelector(getLayoutState, fromLayout.getPortalIdentifier);
