import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Token } from '@app/auth/models/token';
import { User } from '@app/users/models/user';
import { Credentials } from '@app/auth/models/credentials';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '@app/auth/services/token.service';
import { map, catchError } from 'rxjs/operators';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly http: HttpClient, private readonly tokenService: TokenService) {}

  private readonly endpoints = {
    register: 'user/register',
    forgotPassword: 'account/forgotpassword',
    loginCheck: 'account/authenticate',
    getUser: 'user/getcurrentuser',
    resetPassword: 'account/resetpassword',
  };

  register(registerUser: RegisterUser) {
    return this.http.post<RegisterUser>(`${this.endpoints.register}`, registerUser);
  }

  forgotPasswordRequest(forgotPasswordRequest: ForgotPasswordRequest) {
    return this.http.post<ForgotPasswordRequest>(`${this.endpoints.forgotPassword}`, forgotPasswordRequest);
  }

  resetPasswordRequest(resetPasswordRequest: ResetPasswordRequest) {
    return this.http.post<boolean>(`${this.endpoints.resetPassword}`, resetPasswordRequest);
  }

  login(credentials: Credentials): Observable<Token> {
    //Always clear the old token before requesting a new one
    if (this.tokenService.hasToken()) {
      this.tokenService.removeToken();
    }

    return this.http.post(this.endpoints.loginCheck, credentials).pipe(
      map((response: any) => {
        this.tokenService.setToken(
          response.accessToken,
          response.refreshToken,
          response.claims,
          response.features,
          response.isCustomer,
          response.versionNumber
        );
        return response;
      }),
      catchError(() => {       
        return throwError('Ongeldig e-mailadres en/of wachtwoord.');
      })
    );
  }

  retrieveCurrentUser(): Observable<User> {
    return this.http.get<User>(this.endpoints.getUser).pipe(map(user => user));
  }

  logout() {
    this.tokenService.removeToken();
    return of(true);
  }
}
