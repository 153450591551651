import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from '@app/auth/auth-routing.module';
import { ForgotPasswordFormRequestComponent } from '@app/auth/components/forgot-password-request.component';
import { InformationComponent } from '@app/auth/components/information.component';
import { LoginFormComponent } from '@app/auth/components/login-form.component';
import { LogoutConfirmationDialogComponent } from '@app/auth/components/logout-confirmation-dialog.component';
import { RegisterUserFormComponent } from '@app/auth/components/register-user-form.component';
import { ResetPasswordFormComponent } from '@app/auth/components/reset-password.component';
import { WelcomeComponent } from '@app/auth/components/welcome.component';
import { ForgotPasswordPageComponent } from '@app/auth/containers/forgot-password-request-page.component';
import { InformationPageComponent } from '@app/auth/containers/information-page.component';
import { LoginPageComponent } from '@app/auth/containers/login-page.component';
import { RegisterUserPageComponent } from '@app/auth/containers/register-user-page.component';
import { ResetPasswordPageComponent } from '@app/auth/containers/reset-password-page.component';
import { WelcomePageComponent } from '@app/auth/containers/welcome-page.component';
import { AuthEffects } from '@app/auth/effects/auth.effects';
import { reducers } from '@app/auth/reducers';
import { CoreModule } from '@app/core/core.module';
import { MaterialModule } from '@app/material';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StyledAuthorizationPageComponent } from './containers/styled-authorization-page.component';

export const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
  RegisterUserPageComponent,
  RegisterUserFormComponent,
  ForgotPasswordPageComponent,
  ForgotPasswordFormRequestComponent,
  ResetPasswordPageComponent,
  ResetPasswordFormComponent,
  InformationPageComponent,
  InformationComponent,
  WelcomePageComponent,
  WelcomeComponent,
  StyledAuthorizationPageComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AuthRoutingModule,
    CoreModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: COMPONENTS,
})
export class AuthModule {}
