import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from '@app/core/models/message-dialog-parameters';
import { PrintDialogParameters } from '@app/core/models/print-dialog-parameters';

@Component({
  templateUrl: 're-print-dialog.component.html',
  styleUrls: ['./re-print-dialog.component.scss'],
})
export class RePrintDialogComponent {
  header: string;
  message: string;
  buttonType: ButtonType = ButtonType.Ok;
  returnValue: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PrintDialogParameters,
    private readonly dialogRef: MatDialogRef<RePrintDialogComponent>
  ) {
    this.returnValue = data.printResult;  
  }

  close() {
    this.dialogRef.close(this.returnValue);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
