import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/core/models/Filters/Filter';
import { User } from '@app/users/models/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private readonly http: HttpClient) {}

  private readonly endpoints = {    
    get: 'user',
    overview: 'user/list',
    create: 'user/create',
    update: 'user/update',
    count: 'user/count',
    delete: 'user',
    help:'help',
  }; 

   /**
   * Retrieves an array of profiles using the provided filter.
   * @param {Filter} filter The filter. 
   */
  filter(filter: Filter): Observable<User[]> {
    return this.http.post<User[]>(this.endpoints.get, filter).pipe(map(profiles => profiles || []));
  } 
  
   /**
   * Retrieves an array of profiles using the provided filter.
   * @param {Filter} filter The filter. 
   */
  getOverview(filter: Filter): Observable<User[]> {
    return this.http.post<User[]>(this.endpoints.overview, filter).pipe(map(profiles => profiles || []));
  } 

  /**
   * Retrieves the total count of users using the provided filter.
   * @param {Filter} filter The filter. 
   */
  count(filter: Filter): Observable<number> {
    return this.http.post<number>(this.endpoints.count, filter).pipe(map(count => count || 0));
  }

  /**
   * Retrieves the user using the specified identifier.
   * @param {string} id The identifier.
   */
  getById(id: string): Observable<User> {
    return this.http.get<User>(`${this.endpoints.get}/${id}`);
  }

  /**
   * Adds the provided user.
   * @param {User} user The user.
   */
  add(user: User) {
    return this.http.post<User>(`${this.endpoints.create}`, user);
  }

  /**
   * Updates the provided User.
   * @param {User} profile The Profile.
   */
  update(user: User): Observable<User> {
    return this.http.post<User>(this.endpoints.update, user);
  }

  /**
   * Deletes the provided user.
   * @param {string} id The identifier.
   */
  delete(id: string): Observable<string> {
    return this.http.delete<string>(`${this.endpoints.delete}/${id}`);
  }

  /**
   * Get Help file
   */
  help(): Observable<any> {
    return this.http.get(`${this.endpoints.get}/${this.endpoints.help}`, { responseType: "blob" });
  }
}
