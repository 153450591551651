<mat-card class="block-header" id="vt__page-header">
  <mat-card-title>
    {{ mainTitle }}
    <mat-card-title class="block-header__subtitle">
      {{ subTitle }}
    </mat-card-title>
    <mat-card-title class="block-header__subtitle">
      {{ secondSubTitle }}
    </mat-card-title>
  </mat-card-title>
  <div class="block-header__actions"><ng-content></ng-content></div>
</mat-card>
