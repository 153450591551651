import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AuthApiActions } from '@app/auth/actions';
import * as fromAuth from '@app/auth/reducers';
import { mergeMap } from 'rxjs/operators';
import { AuthGuard } from '@app/auth/guards/auth-guard.service';
import { hasClaims } from '@app/core/models/Claims/ClaimFunctions';

@Injectable()
export class ClaimGuard {
  constructor(
    private readonly store: Store<fromAuth.State>,
    private readonly authGuard: AuthGuard
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authGuard.canActivate(route, state).pipe(
      mergeMap(authed => {
        if (authed && hasClaims(route.data.requiredClaims)) {
          return of(true);
        }

        this.store.dispatch(new AuthApiActions.LoginRedirect(state.url));
        return of(false);
      })
    );
  }
}
