import { LayoutActions } from '@app/core/actions';
import { SnackBar } from '@app/core/models/SnackBar';
import * as fromRoot  from '@app/reducers';
import { Portal } from '../models/Portal/portal.model';
import { StringData } from '../models/Portal/portal-strings.model';

export interface LayoutState  {
  showErrorSnackBar: boolean;
  showSuccessSnackBar: boolean;
  successSnackBar: SnackBar;
  errorSnackBar: SnackBar;
  isValidPortal: boolean;
  isValidPortalLoaded: boolean;
  portal: Portal;
}

export interface State extends fromRoot.State {
  layout: LayoutState;
}

const initialState: LayoutState = {
  showErrorSnackBar: false,
  showSuccessSnackBar: false,
  errorSnackBar: { messages: [] },
  successSnackBar: { messages: [] },
  isValidPortal: false,
  isValidPortalLoaded: false,
  portal: {
    id: "",
    vtCustomerId: "",
    portalIdentifier: "",
    favicon: "",
    portalTitle: "",
    logo: "",
    stylesheet: "",
    strings: new StringData(),
    portalSettings: []
  },
};

export function reducer(state: LayoutState = initialState, action: LayoutActions.LayoutActionsUnion): LayoutState {
  switch (action.type) {
    case LayoutActions.LayoutActionTypes.OpenErrorSnackBar:
      return {
        ...state,
        showErrorSnackBar: true,
        errorSnackBar: {
          ...action.payload,
          config: { panelClass: 'error-snack-bar' },
        },
      };
    case LayoutActions.LayoutActionTypes.CloseErrorSnackBar:
      return { ...state, showErrorSnackBar: false };
    case LayoutActions.LayoutActionTypes.OpenSuccessSnackBar:
      return {
        ...state,
        showSuccessSnackBar: true,
        successSnackBar: {
          ...action.payload,
          config: { panelClass: 'success-snack-bar' },
        },
      };
    case LayoutActions.LayoutActionTypes.CloseSuccessSnackBar:
      return { ...state, showSuccessSnackBar: false };
    case LayoutActions.LayoutActionTypes.LoadStyleSuccess:
      return {...state, portal: action.payload};
    case LayoutActions.LayoutActionTypes.CheckValidPortal:
      return {...state, isValidPortalLoaded: false};
    case LayoutActions.LayoutActionTypes.CheckValidPortalSuccess:
      return {...state, isValidPortalLoaded: true, isValidPortal: action.payload};
    default:
      return state;
  }
}


export const getShowErrorSnackBar = (state: LayoutState) => state.showErrorSnackBar;
export const getErrorSnackBar = (state: LayoutState) => state.errorSnackBar;

export const getShowSuccessSnackBar = (state: LayoutState) => state.showSuccessSnackBar;
export const getSuccessSnackBar = (state: LayoutState) => state.successSnackBar;

export const getIsValidPortal = (state: LayoutState) => state.isValidPortal;
export const getIsValidPortalLoaded = (state: LayoutState) => state.isValidPortalLoaded;

export const getPortalIdentifier = (state: LayoutState) => state.portal.portalIdentifier;

export const getPortal = (state: LayoutState) => state.portal;
export const getPortalSettings = (state: LayoutState) => state.portal.portalSettings;

export const getLogo = (state: LayoutState) => state.portal.logo;
export const getTitle = (state: LayoutState) => state.portal.portalTitle;

export const getRegistrationSuccessText = (state: LayoutState) => state.portal.strings.registrationSuccessText;
