import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenService } from '@app/auth/services/token.service';

@Injectable()
export class WelcomeGuard {
  constructor(private readonly tokenService: TokenService, private readonly router: Router) {}

  canActivate(): Observable<boolean> {   
    const isCustomer = this.tokenService.getIsCustomer();
    if (isCustomer !== null) {     
        this.router.navigate([getWelcomePage(isCustomer)]);    
    }

    return of(true);
  }
}

/**
 * Get the url for the welcome page for the current user
 * @param {boolean} isCustomer Is a customer
 * @returns {string} The url
 */
export function getWelcomePage(isCustomer: boolean): string {
  return '/orders';
}
