<mat-sidenav-container fullscreen>
  <vt-toolbar
  [logo]="logo$ | async"
  [title]="title$ | async"
  (openHelp)="onOpenHelp()"
  ></vt-toolbar>

  <ng-content></ng-content>

  <vt-footer></vt-footer>
</mat-sidenav-container>
