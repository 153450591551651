import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  Base64ImageDisplayComponent,
  Base64ImageUploadComponent,
  Base64XmlUploadComponent,
  ConfirmDeleteComponent,
  ErrorComponent,
  FooterComponent,
  HeaderActionsComponent,
  LayoutComponent,
  MessageDialogComponent,
  NavItemComponent,
  RePrintDialogComponent,
  ToolbarComponent
} from '@app/core/components';
import { AppComponent } from '@app/core/containers/app.component';
import { NotFoundPageComponent } from '@app/core/containers/not-found-page.component';
import { HasFeatureDirective } from '@app/core/directives/has-feature.directive';
import { HasNotFeatureDirective } from '@app/core/directives/has-not-feature.directive';
import { HasClaimsDirective } from '@app/core/directives/hasClaims.directive';
import { IsCustomerDirective } from '@app/core/directives/isCustomer.directive';
import { LayoutEffects } from '@app/core/effects/layout.effects';
import { MaterialModule } from '@app/material';
import { EffectsModule } from '@ngrx/effects';
import { ThemeService } from './services/theme.service';

export const COMPONENTS = [
  AppComponent,
  NotFoundPageComponent,
  LayoutComponent,
  HeaderActionsComponent,
  NavItemComponent,
  ToolbarComponent,
  FooterComponent,
  Base64ImageDisplayComponent,
  Base64ImageUploadComponent,
  Base64XmlUploadComponent,
  ErrorComponent,
  ConfirmDeleteComponent,
  MessageDialogComponent,
  HasClaimsDirective,
  IsCustomerDirective,
  HasFeatureDirective,
  HasNotFeatureDirective,
  RePrintDialogComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, EffectsModule.forFeature([LayoutEffects])],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [ThemeService],
})
export class CoreModule {}
