import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class ResetPasswordGuard {
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return route.queryParams.code ? of(true) : of(false);
  }
}
