import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import * as fromAuth from '@app/auth/reducers';
import * as fromLayout from '@app/reducers';
import { LayoutActions } from '@app/core/actions';
import { mapAction, mapConfig, SnackBar } from '@app/core/models/SnackBar';
import { AppInsightService } from '@app/core/services/application-insights';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CheckValidPortal } from '../actions/layout.actions';

@Component({
  selector: 'vt-app',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  errorSnackBarSubscription: Subscription;
  errorSnackBarReference: MatSnackBarRef<any>;

  errorSnackBar$: Observable<SnackBar>;
  showErrorSnackBar$: Observable<boolean>;

  successSnackBarSubscription: Subscription;
  successSnackBarReference: MatSnackBarRef<any>;

  successSnackBar$: Observable<SnackBar>;
  showSuccessSnackBar$: Observable<boolean>;

  isValidPortal$: Observable<boolean>;
  isValidPortalLoaded$: Observable<boolean>;

  /**
   * Initializes a new instance of the AppComponent class.
   * @param {SnackBar} snackBar The Material SnackBar reference.
   * @param {Store<fromAuth.State>} store The redux store.
   */
  constructor(
    public snackBar: MatSnackBar,
    private readonly store: Store<fromAuth.State>,
    private readonly layoutStore: Store<fromLayout.State>,
    private readonly appInsightService: AppInsightService
  ) {
    this.errorSnackBar$ = this.store.select(fromAuth.getErrorSnackBar);
    this.showErrorSnackBar$ = this.store.select(fromAuth.getShowErrorSnackBar);
    this.successSnackBar$ = this.store.select(fromAuth.getSuccessSnackBar);
    this.showSuccessSnackBar$ = this.store.select(fromAuth.getShowSuccessSnackBar);

    this.isValidPortal$ = this.layoutStore.select(fromLayout.getIsValidPortal);
    this.isValidPortalLoaded$ = this.layoutStore.select(fromLayout.getIsValidPortalLoaded);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */
  ngOnInit() {
    this.layoutStore.dispatch(new CheckValidPortal);

    this.errorSnackBarSubscription = this.showErrorSnackBar$.subscribe((show: boolean) =>
      show ? this.openErrorSnackBar() : null
    );
    this.successSnackBarSubscription = this.showSuccessSnackBar$.subscribe((show: boolean) =>
      show ? this.openSuccessSnackBar() : null
    );

    this.appInsightService.logEvent('Portal.Client.Loaded');
  }

  /**
   * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
   */
  ngOnDestroy() {
    this.errorSnackBarSubscription?.unsubscribe();
    this.successSnackBarSubscription?.unsubscribe();
  }

  /**
   * Open the error SnackBar.
   */
  private openErrorSnackBar() {
    let errorMessage = '';
    this.errorSnackBar$.pipe(first()).subscribe((snackBar: SnackBar) => {
      snackBar.messages.forEach(message => {
        errorMessage = errorMessage.concat(message, '\n');
      });
      this.errorSnackBarReference = this.snackBar.open(
        errorMessage,
        mapAction(snackBar.action),
        mapConfig(snackBar.config)
      );
      this.errorSnackBarReference
        .afterDismissed()
        .pipe(first())
        .subscribe(() => {
          this.closeErrorSnackBar();
        });
    });
  }

  /**
   * Close the error SnackBar.
   */
  private closeErrorSnackBar() {
    this.store.dispatch(new LayoutActions.CloseErrorSnackBar());
  }

  /**
   * Open the success SnackBar.
   */
  private openSuccessSnackBar() {
    let successMessage = '';
    this.successSnackBar$.pipe(first()).subscribe((snackBar: SnackBar) => {
      snackBar.messages.forEach(message => {
        successMessage = successMessage.concat(message, '\n');
      });
      this.successSnackBarReference = this.snackBar.open(
        successMessage,
        mapAction(snackBar.action),
        mapConfig(snackBar.config)
      );
      this.successSnackBarReference
        .afterDismissed()
        .pipe(first())
        .subscribe(() => {
          this.closeSuccessSnackBar();
        });
    });
  }

  /**
   * Close the success SnackBar.
   */
  private closeSuccessSnackBar() {
    this.store.dispatch(new LayoutActions.CloseSuccessSnackBar());
  }
}
