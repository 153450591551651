import { Component, Output, EventEmitter, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as AuthActions from '@app/auth/actions/auth.actions';
import * as fromAuth from '@app/auth/reducers';
import { getFullName, User } from '@app/users/models/user';
import { hasClaims } from '@app/core/models/Claims/ClaimFunctions';
import { operationPriorityTypes } from '@app/core/constants/operationPriorityTypes';
import { AppConfigService } from '@app/core/services/app-config-service';

@Component({
  selector: 'vt-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  loggedIn$: Observable<boolean>;
  userName: string;
  user: User;
  operationPriorityTypes = operationPriorityTypes;

  @Input() logo:string;
  @Input() title: string;

  @Output() openHelp = new EventEmitter();

  constructor(
    private readonly store: Store<fromAuth.State>,
  ) {
    this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
    this.store.pipe(select(fromAuth.getUser)).subscribe(user => {
      if(user != null){
        this.user = user;
        this.userName = getFullName(user);
      }
    });
  }

  logout() {
    this.store.dispatch(new AuthActions.LogoutConfirmation());
  }

  showEnvironmentWarning(): boolean{
    return AppConfigService.config.App.Environment.toLowerCase() !== 'productie'
      && AppConfigService.config.App.Environment.toLowerCase() !== 'lokaal';
  }

  environmentWarning(): string{
    return `De huidige omgeving is ${AppConfigService.config.App.Environment}`;
  }

  hasClaims(requiredClaims: string[]): boolean{
    return hasClaims(requiredClaims);
  }

  hasAnyClaims(requiredClaims: string[][]): boolean{
    let hasClaim = false;
    requiredClaims.forEach(requiredClaim => {
      if(hasClaims(requiredClaim)){
        hasClaim = true;
      }
    });
    return hasClaim;
  }

  showHelp() {
    this.openHelp.emit();
  }
}
