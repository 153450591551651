class OperationPriorityTypes {
  SillCutting = 1000;
  SillMilling = 1050;
  SillClosingPlate = 1080;
  SillTryma = 1090;
  SillHeadWorking = 1100;
  GlassCutting = 2000;
  GlassSlantedCutting = 2050;
  Stegherr = 2070;
  AluminumCutting = 3000;
  AluminumMilling = 3050;
  AluminumMillingCaps = 3100;
  AluminumWaterChannel = 3115;
  AluminumClosingPlate = 3150;
  AluminumHeadworking = 3200;
  PlasticCutting = 4000;
  ExtraKeys = 5000;
  PVCCutting = 6000;
  RVSCutting = 7000;
  FetchMaterial = 8000;
}
export const operationPriorityTypes = new OperationPriorityTypes();
