import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, exhaustMap, map, tap, mergeMap, switchMap } from 'rxjs/operators';
import {
  LoginPageActions,
  AuthActions,
  AuthApiActions,
  RegisterUserPageActions,
  ForgotPasswordPageActions,
} from '@app/auth/actions';
import { Credentials } from '@app/auth/models/credentials';
import { AuthService } from '@app/auth/services/auth.service';
import { LogoutConfirmationDialogComponent } from '@app/auth/components/logout-confirmation-dialog.component';
import { getWelcomePage } from '@app/auth/guards/welcome-guard.service';
import { Action } from '@ngrx/store';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';
import { LayoutActions } from '@app/core/actions';
import { Error } from '@app/core/models/Error';

@Injectable()
export class AuthEffects {
  
  forgotPasswordRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ForgotPasswordPageActions.SubmitForgotPasswordRequest>(
      ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitForgotPassword
    ),
    map(action => action.payload),
    mergeMap((forgotPasswordRequest: ForgotPasswordRequest) =>
      this.authService.forgotPasswordRequest(forgotPasswordRequest).pipe(
        map(response => new AuthApiActions.ForgotPasswordRequestSuccess(response)),
        catchError(response => of(new AuthApiActions.ForgotPasswordRequestFailure(response.error.Errors)))
      )
    )
  ));

  
  resetPasswordRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ForgotPasswordPageActions.SubmitResetPasswordRequest>(
      ForgotPasswordPageActions.RegisterForgotPasswordPageActionTypes.SubmitResetPassword
    ),
    map(action => action.payload),
    mergeMap((resetPasswordRequest: ResetPasswordRequest) =>
      this.authService.resetPasswordRequest(resetPasswordRequest).pipe(
        switchMap(response => [
          new AuthApiActions.ResetPasswordRequestSuccess(response),
          new LayoutActions.OpenSuccessSnackBar({
            messages: ['Wachtwoord gewijzigd'],
          }),
        ]),
        tap(() => this.router.navigate(['/login'])),
        catchError(response => of(new AuthApiActions.ResetPasswordRequestFailure(response.error.Errors)))
      )
    )
  ));

  
  error$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AuthApiActions.ResetPasswordRequestFailure>(AuthApiActions.AuthApiActionTypes.ResetPasswordRequestFailure),
    map(action => action.payload),
    mergeMap((errorMessages: Error[]) => {
      return of(new LayoutActions.OpenErrorSnackBar({ messages: errorMessages.map(message => message.Value) }));
    })
  ));

  
  register$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<RegisterUserPageActions.SubmitRegisterUser>(
      RegisterUserPageActions.RegisterUserPageActionTypes.SubmitRegisterUser
    ),
    map(action => action.payload),
    mergeMap((registerUser: RegisterUser) =>
      this.authService.register(registerUser).pipe(
        map(response => new AuthApiActions.RegisterUserSuccess(response)),
        tap(() => this.router.navigate(['/information'])),
        catchError(response => of(new AuthApiActions.RegisterUserFailure(response.error.Errors)))
      )
    )
  ));

  
  login$ = createEffect(() => this.actions$.pipe(
    ofType<LoginPageActions.Login>(LoginPageActions.LoginPageActionTypes.Login),
    map(action => action.payload),
    exhaustMap((data: { credentials: Credentials, redirectURL: string | null }) =>
      this.authService.login(data.credentials).pipe(
        map(tokens => new AuthApiActions.LoginSuccess({ tokens })),
        tap(loginSuccess => {               
          if (data.redirectURL) {          
            this.router.navigate([data.redirectURL]);
          } else {
            this.router.navigate([getWelcomePage(loginSuccess.payload.tokens.isCustomer)]);
          }
        }),
        catchError(error => of(new AuthApiActions.LoginFailure({ error })))
      )
    )
  ));

  
  loginRedirect$ = createEffect(() => this.actions$.pipe(
    ofType<AuthApiActions.LoginRedirect>(AuthApiActions.AuthApiActionTypes.LoginRedirect),
    map(action => action.payload),
    mergeMap((redirectURL: string) => this.router.navigate(['/login'], { queryParams: { redirectURL } }).then(() => window.location.reload()))
  ), { dispatch: false });

  
  logoutRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.Logout),
    tap(authed => {
      this.router.navigate(['/login']);
    })
  ), { dispatch: false });

  
  logoutConfirmation$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.LogoutConfirmation),
    exhaustMap(() => {
      const dialogRef = this.dialog.open<LogoutConfirmationDialogComponent, undefined, boolean>(
        LogoutConfirmationDialogComponent
      );

      return dialogRef.afterClosed();
    }),
    map(result => (result ? new AuthActions.Logout() : new AuthActions.LogoutConfirmationDismiss()))
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}
}
