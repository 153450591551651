import { Component } from '@angular/core';
import { LayoutState } from '@app/core/reducers/layout.reducer';
import * as fromRoot from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'vt-welcome',
    templateUrl: './welcome.component.html'
})

export class WelcomeComponent {

  layoutState$: Observable<LayoutState>;

  companyName: string;

  constructor(
    private readonly store: Store<fromRoot.State>
  ) {
    this.layoutState$ = this.store.pipe(select(fromRoot.getLayoutState));
  }

  ngOnInit() {
    this.layoutState$.subscribe(layout => this.companyName = layout.portal.portalTitle);
  }
}
