import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlNL extends MatPaginatorIntl {
  itemsPerPageLabel = 'Resultaten per pagina';
  nextPageLabel     = 'Volgende pagina';
  lastPageLabel     = 'Laatste pagina';
  firstPageLabel     = 'Eerste pagina';
  previousPageLabel = 'Vorige pagina';

  /**
   * Returns the label for the range of items within the current page and the length of the whole list.
   */
  getRangeLabel = function (page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return '0 van ' + length;
    }

    const maxLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < maxLength ? Math.min(startIndex + pageSize, maxLength) : startIndex + pageSize;

    return `${(startIndex + 1)} - ${endIndex} van ${maxLength}`;
  };
}
