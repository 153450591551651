import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'vt-forgot-password-request-form',
  templateUrl: './forgot-password-request.component.html',
  styleUrls: ['./forgot-password-request.component.scss'],
})
export class ForgotPasswordFormRequestComponent implements OnInit {
  @Input() forgotPasswordRequest: ForgotPasswordRequest;

  @Output() submitted = new EventEmitter<ForgotPasswordRequest>();

  form: UntypedFormGroup;

  formSubmitted = false;

  constructor() {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      Email: new UntypedFormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' }),
    });
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
      this.formSubmitted = true;
    }
  }
}
