import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Credentials } from '@app/auth/models/credentials';
import * as fromAuth from '@app/auth/reducers';
import { LoginPageActions } from '@app/auth/actions';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LayoutActions } from '@app/core/actions';

@Component({
  selector: 'vt-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent {
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  error$ = this.store.pipe(select(fromAuth.getLoginPageError));

  actionsSubscription: Subscription;
  redirectURL: string | null;

  constructor(private readonly store: Store<fromAuth.State>, route: ActivatedRoute) {
    this.actionsSubscription = route.queryParams
      .pipe(
        map(params => {
          this.redirectURL = params.redirectURL;
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.actionsSubscription?.unsubscribe();
  }

  onSubmit(credentials: Credentials) {
    this.store.dispatch(new LoginPageActions.Login({ credentials, redirectURL: this.redirectURL }));
  }

  onDownloadHelp() {
    this.store.dispatch(new LayoutActions.DownloadHelp());
  }
}
