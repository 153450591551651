<mat-toolbar class="hidden-print" [class.not-production-env]="showEnvironmentWarning()">
  <mat-toolbar-row>
    <img alt="Logo {{title}}" class="logo" [src]="logo" [title]="title" />

    <!-- Klanten -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasAnyClaims([['Customer.Delete'], ['UserManagement.Read']])"
      [matMenuTriggerFor]="Customers">
      Klanten
    </vt-nav-item>

    <mat-menu #Customers="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['Customer.Delete'])" [routerLink]="['/customers']" mat-menu-item>
        Klanten
      </button>
      <button *ngIf="(loggedIn$ | async) && hasClaims(['UserManagement.Read'])" [routerLink]="['/users']" mat-menu-item>
        Gebruikers
      </button>
    </mat-menu>

    <!-- Orders -->
    <vt-nav-item *ngIf="(loggedIn$ | async) && hasClaims(['Order.Read'])" [routerLink]="['/orders']">
      Orders
    </vt-nav-item>

    <span class="spacer"></span>

    <ng-container *ngIf="showEnvironmentWarning()">
      {{environmentWarning()}}
    </ng-container>

    <span class="spacer"></span>

    <button *ngIf="(loggedIn$ | async)" mat-button [matMenuTriggerFor]="user">
      <i class="material-icons black" aria-hidden="true">account_circle</i> {{ userName }}
    </button>
    <mat-menu #user="matMenu">
      <button *ngIf="(loggedIn$ | async) && hasClaims(['UserProfile.Read'])" [routerLink]="['/userprofile']"
        mat-menu-item>
        Mijn profiel
      </button>
      <button *ngIf="(loggedIn$ | async)" (click)="logout()" mat-menu-item>Uitloggen</button>
    </mat-menu>
    <button *ngIf="(loggedIn$ | async)" mat-button class="help-button" (click)="showHelp()">
      <i class="material-icons grey" aria-hidden="true">help</i>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
