import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ResetPasswordRequest } from '@app/auth/models/resetPasswordRequest';
import { ActivatedRoute } from '@angular/router';
import { equalControlsValidator } from '@app/core/validators/equalControls.validator';

@Component({
  selector: 'vt-reset-password-form',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  @Input() resetPasswordRequest: ResetPasswordRequest;

  @Output() submitted = new EventEmitter<ResetPasswordRequest>();

  form: UntypedFormGroup;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      Code: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
      Email: new UntypedFormControl('', { validators: [Validators.required, Validators.email], updateOn: 'blur' }),
      Passwords: new UntypedFormGroup(
        {
          NewPassword: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur'}),
          NewPasswordRepeat: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur'})
        },
        this.validatePasswords
      ),
    });

    this.form.patchValue({ Code: this.route.snapshot.queryParams['code'] });
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit({
        code: this.form.value.Code,
        email: this.form.value.Email,
        newPassword: this.form.value.Passwords.NewPassword,
      });
    }
  }

  /**
   * Validator to check if password and password repeat are provided and equal.
   * @param {AbstractControl} form The form.
   * @returns {ValidationErrors | null} Error object.
   * @memberof ResetPasswordFormComponent
   */
  validatePasswords(form: AbstractControl): ValidationErrors | null {
    return equalControlsValidator(form.get('NewPassword'), form.get('NewPasswordRepeat'));
  }
}
