import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Portal } from "../models/Portal/portal.model";
import { PortalStrings } from "../models/Portal/portal-strings.model";

@Injectable({
  providedIn: "root",
})
export class ThemeService {

  private readonly endpoints = {
    getPortal: "getPortal",
    isValidPortal: "isValidPortal"
  };

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly http: HttpClient,
  ) {
  }

  getPortalStyle(): Observable<Portal> {
    return this.http.get<Portal>(`${this.endpoints.getPortal}`);
  }

  checkValidPortal(): Observable<boolean> {
    return this.http.get<boolean>(`${this.endpoints.isValidPortal}`);
  }

  setup(settings: Portal): Portal {
    this.setStylesheet(settings);
    this.setFavicon(settings);
    this.setTitle(settings);
    this.setPortalStrings(settings);

    const logo = `/assets/images/logo-${ settings.portalIdentifier }`;
    
    settings.logo = `${logo}.png`;

    return settings;
  }

  private setStylesheet(settings: Portal) {
    const identifier = settings.portalIdentifier;
    if (identifier === undefined || identifier.length === 0) {
      return;
    }

    settings.stylesheet = identifier + '.css';

    const link = this.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', settings.stylesheet);
    this.document.head.appendChild(link);
  }

  private setFavicon(settings: Portal) {
    const identifier = settings.portalIdentifier;
    if (identifier === undefined || identifier.length === 0) {
      return;
    }

    settings.favicon = `/assets/favicons/${ identifier }.ico`;

    const link = this.document.querySelector("link[rel~='icon']");
    link.setAttribute('href', settings.favicon);
    this.document.head.appendChild(link);
  }

  private setTitle(settings: Portal) {
    if(settings.portalTitle === undefined || settings.portalTitle.length === 0) {
      return;
    }
    this.document.title = settings.portalTitle;
  }

  private setPortalStrings(settings: Portal) {
    const id = settings.portalIdentifier;
    const strings: PortalStrings = require('../../../assets/localization/portal-text.json');
    const data = strings.Portals.find(x => x.id === id);

    settings.strings = data;
  }
}
