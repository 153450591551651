import { Action } from '@ngrx/store';
import { Token } from '@app/auth/models/token';
import { User } from '@app/users/models/user';
import { RegisterUser } from '@app/auth/models/registerUser';
import { ForgotPasswordRequest } from '@app/auth/models/forgotPasswordRequest';
import { Error } from '@app/core/models/Error';

export enum AuthApiActionTypes {
  LoginSuccess = '[Auth/API] Login Success',
  LoginFailure = '[Auth/API] Login Failure',
  LoginRedirect = '[Auth/API] Login Redirect',
  RetrieveUserSuccess = '[Auth/API] Retrieve User Success',
  RegisterUserSuccess = '[Auth/API] Register User Success',
  RegisterUserFailure = '[Auth/API] Register User Failure',
  ForgotPasswordRequestSuccess = '[Auth/API] Forgot Password Request Success',
  ForgotPasswordRequestFailure = '[Auth/API] Forgot Password Request Failure',
  ResetPasswordRequestSuccess = '[Auth/API] Reset Password Request Success',
  ResetPasswordRequestFailure = '[Auth/API] Reset Password Request Failure',
}

export class LoginSuccess implements Action {
  readonly type = AuthApiActionTypes.LoginSuccess;

  constructor(public payload: { tokens: Token }) {}
}

export class LoginFailure implements Action {
  readonly type = AuthApiActionTypes.LoginFailure;

  constructor(public payload: { error: any }) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthApiActionTypes.LoginRedirect;

  constructor(public payload: string ) {}
}

export class RetrieveUserSuccess implements Action {
  readonly type = AuthApiActionTypes.RetrieveUserSuccess;

  constructor(public payload: { user: User }) {}
}

/**
 * Register User Actions
 */
export class RegisterUserSuccess implements Action {
  readonly type = AuthApiActionTypes.RegisterUserSuccess;

  constructor(public payload: RegisterUser) {}
}

export class RegisterUserFailure implements Action {
  readonly type = AuthApiActionTypes.RegisterUserFailure;

  constructor(public payload: Error[]) {}
}

/**
 * Forgot Password Actions
 */
export class ForgotPasswordRequestSuccess implements Action {
  readonly type = AuthApiActionTypes.ForgotPasswordRequestSuccess;

  constructor(public payload: ForgotPasswordRequest) {}
}

export class ForgotPasswordRequestFailure implements Action {
  readonly type = AuthApiActionTypes.ForgotPasswordRequestFailure;

  constructor(public payload: { error: Error[] }) {}
}

/**
 * Reset Password Actions
 */
export class ResetPasswordRequestSuccess implements Action {
  readonly type = AuthApiActionTypes.ResetPasswordRequestSuccess;

  constructor(public payload: boolean) {}
}

export class ResetPasswordRequestFailure implements Action {
  readonly type = AuthApiActionTypes.ResetPasswordRequestFailure;

  constructor(public payload: Error[]) {}
}

export type AuthApiActionsUnion =
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | RetrieveUserSuccess
  | RegisterUserSuccess
  | RegisterUserFailure
  | ForgotPasswordRequestSuccess
  | ForgotPasswordRequestFailure
  | ResetPasswordRequestSuccess
  | ResetPasswordRequestFailure;
