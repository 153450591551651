import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutActions } from '@app/core/actions';
import { RePrintDialogComponent } from '@app/core/components';
import { PrintDialogParameters } from '@app/core/models/print-dialog-parameters';
import { UsersService } from '@app/users/services/users.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, switchMap, map, mergeMap } from 'rxjs/operators';
import { ThemeService } from '../services/theme.service';

@Injectable()
export class LayoutEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly usersService: UsersService,
    private readonly themeService: ThemeService,
    private readonly dialog: MatDialog
  ) {}

  /**
   * Get and display manual pdf
   */
  
  downloadHelp$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.DownloadHelp>(LayoutActions.LayoutActionTypes.DownloadHelp),
    mergeMap(() =>
      this.usersService.help().pipe(
        map(response => {
          try {
            window.navigator.msSaveOrOpenBlob(response, `Handleiding bestelsysteem.pdf`);
          } catch {
            const fileURL = URL.createObjectURL(response);
            window.open(fileURL, '_blank');
          }
        })
      )
    )
  ), { dispatch: false });

  
  loadStyle$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.LoadStyle>(LayoutActions.LayoutActionTypes.LoadStyle),
    switchMap(() =>
      this.themeService.getPortalStyle().pipe(
        map((settings) => {
          this.themeService.setup(settings);
          return new LayoutActions.LoadStyleSuccess(settings);
        })
      )
    )
  ));

  
  checkValidPortal$ = createEffect(() => this.actions$.pipe(
    ofType<LayoutActions.CheckValidPortal>(LayoutActions.LayoutActionTypes.CheckValidPortal),
    switchMap(() =>
      this.themeService.checkValidPortal().pipe(
        map((isValidPortal) => new LayoutActions.CheckValidPortalSuccess(isValidPortal))
      )
    )
  ));
}
