import { Component, Injectable } from '@angular/core';
import { LayoutActions } from '@app/core/actions';
import * as fromRoot from '@app/reducers';
import * as fromStyles from '@app/reducers/style';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'vt-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
@Injectable()
export class LayoutComponent {

  logo$: Observable<string>;
  title$: Observable<string>;

  constructor(
    private readonly store: Store<fromRoot.State>,
  ) {
    this.logo$ = this.store.select(fromStyles.getLogo);
    this.title$ = this.store.select(fromStyles.getTitle);
  }

  ngOnInit() {
    this.store.dispatch(new LayoutActions.LoadStyle);
  }

  onOpenHelp() {
    this.store.dispatch(new LayoutActions.DownloadHelp());
  }
}
