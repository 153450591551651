import { Role } from '@app/core/models/Role';

export interface User {
  id: string;
  email: string;
  password: string;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  phoneNumber: string;
  roles: Role[];
  customerId: string;  
  userNumber: string;
}

/**
 * Get the roles to display.
 * @param {User} user The user.
 * @returns {string} Comma seperated roles.
 */
export function getRoleString(user: User): string {
  return user.roles.length > 0 ? user.roles[0].description : "";
}
  /**
 * Get the fullName to display.
 * @param {User} user The user.
 * @returns {string} concat of firstName lastNamePrefix and lastName.
 */
export function getFullName(user: User): string {
  return user.lastNamePrefix
    ? `${user.firstName} ${user.lastNamePrefix} ${user.lastName}`
    : `${user.firstName} ${user.lastName}`;
}

